import { Button, Form, Select, Space, Spin, Table, message } from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getCompanyList } from 'reduxs/operation/company'
import { getProtocolList, removeProtocol } from 'reduxs/operation/protocol'
import { enumCodeList } from 'reduxs/system/enun'
import AddModal from '../Compontents/AddModal'
import ContentModal from '../Compontents/ContentModal'
import { columns } from './_help'
const { Option } = Select
let options = {}
const CompanyProtocol = () => {
  const [editeData, setEditeData] = useState({}) // 编辑一行的数据
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState({}) // 页码相关
  const [pageData, setPageData] = useState([]) // 列表
  const [companList, setCompanList] = useState([])
  const [typeList, setTyoeList] = useState([])
  useEffect(() => {
    options = {
      page: 1,
      limit: 10,
    }
    getEnumList()
    getCompany()
    // getDicType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取支付类型列表
  const getList = async () => {
    setLoading(true)
    const res = await getProtocolList(options)
    setLoading(false)
    if (res.result === 200) {
      const data = res.data || []
      const page = res.page || 0
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 获取字典
  const getEnumList = async () => {
    const res = await enumCodeList('COMP_PROTOCOL')
    if (res.result === 200) {
      setTyoeList(res.data || [])
      getList(res.data || [])
    }
  }
  // 获取公司
  const getCompany = async () => {
    const res = await getCompanyList({ status: 5, paging: false })
    if (res.result === 200) {
      setCompanList(res.data || [])
    }
  }
  // 删除
  const handRemove = async row => {
    const { companyProtocolId } = row
    const res = await removeProtocol(companyProtocolId)
    if (res.result === 200) {
      message.success('删除成功~')
      getList(typeList)
    }
  }
  // 获取字典类型
  // 编辑
  const handEdite = row => {
    const data = { ...row, _status: true }
    setEditeData(data)
  }
  // 新增
  const handAdd = () => {
    const data = { _status: true }
    setEditeData(data)
  }
  // 内容
  const handContent = row => {
    const data = { ...row, contnetStatus: true }
    setEditeData(data)
  }
  // 事件合集
  const eventData = {
    handEdite,
    handRemove,
    handContent,
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList(typeList)
  }
  // 搜索
  const onFinish = values => {
    options = { page: 1, limit: 10, ...values }
    getList(typeList)
  }
  // 新增编辑成功
  const handSuccess = () => {
    setEditeData({})
    getList(typeList)
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData._status && (
          <AddModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
            companList={companList}
            typeList={typeList}
          />
        )}
        {editeData.contnetStatus && (
          <ContentModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}

        <Form onFinish={onFinish}>
          {/* <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '200px' }} />
          </Form.Item> */}
          <Form.Item name="companyId" className="formItem">
            <Select placeholder="请选择" allowClear style={{ width: '200px' }}>
              {companList.map((item, index) => (
                <Option value={item.companyId} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type" className="formItem">
            <Select placeholder="请选择" allowClear>
              {typeList.map((item, index) => (
                <Option value={item.val} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" allowClear>
              <Option value={1} key={1}>
                启用
              </Option>
              <Option value={0} key={0}>
                禁用
              </Option>
            </Select>
          </Form.Item>
          <Form.Item className="formItem">
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <ButtonGroup onClick={handAdd} btnId={btnApiList.companyAdd}>
                新增
              </ButtonGroup>
            </Space>
          </Form.Item>
        </Form>
        <Table
          dataSource={pageData}
          rowKey={item => item.companyProtocolId} //React规范中的array都必须有key
          columns={columns(eventData)}
          size="small"
          onChange={changePage}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: pages.count || 0,
            showTotal: total => `共${total}条`,
            defaultPageSize: 10,
            pageSize: options.limit,
            defaultCurrent: 1,
            current: options.page,
            // pageSizeOptions: [10, 30, 50],
            position: ['bottomLeft'],
          }}
        />
      </Spin>
    </div>
  )
}
export default withRouter(CompanyProtocol)
