import { LeftOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Col, Form, message, Row, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  adsOrderBenefit,
  adsOrderDetails,
  adsOrderMail,
  analyzing,
  emaiSend,
  payOrderList,
} from 'reduxs/advertising/common'
import { benefitColumns, emailColumns, payColumns, payTypeList } from './_help'
import styles from './details.module.less'
//
const AdvertiOrderDetails = props => {
  const { history } = props
  const { state } = props.location || {}
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState({}) //投放订单详情数据
  const [mailList, setMailList] = useState([]) // 投放订单邮箱
  const [benefit, setBenefit] = useState({}) // 投放订单权益
  const [payList, setPayList] = useState([])
  useEffect(() => {
    // getList()
    // getTypeData()
    getDetails()
    getMailList()
    getAdsOrderBenefit()
    getPayOrderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 刷新
  const handLoad = () => {
    getDetails()
    getMailList()
    getAdsOrderBenefit()
    getPayOrderList()
  }
  const getPayOrderList = async () => {
    const { adsOrderId } = state
    const res = await payOrderList({ adsOrderId: adsOrderId })
    if (res.result === 200) {
      const orderList = res.data || []
      orderList.forEach((item, index) => {
        item.index = index + 1
      })
      // res.data.benefitFrees = benefitFrees
      setPayList(orderList || {})
    }
  }
  // 获取投放订单权益
  const getAdsOrderBenefit = async () => {
    const { adsOrderId } = state
    const res = await adsOrderBenefit(adsOrderId)
    if (res.result === 200) {
      const benefitFrees = res.data?.benefitFrees || []
      benefitFrees.forEach((item, index) => {
        item.index = index + 1
      })
      res.data.benefitFrees = benefitFrees
      setBenefit(res.data || {})
    }
  }

  // 获取邮件列表
  const getMailList = async () => {
    const { adsOrderId } = state
    const res = await adsOrderMail(adsOrderId)
    if (res.result === 200) {
      const list = res.data || []
      list.forEach((item, index) => {
        item.index = index + 1
      })
      setMailList(res.data || [])
    }
  }

  // 获取详情
  const getDetails = async () => {
    setLoading(true)
    const { adsOrderId } = state
    const res = await adsOrderDetails(adsOrderId)
    setLoading(false)
    if (res.result === 200) {
      const { payStatus } = res.data
      const findData = payTypeList.find(item => item.type === payStatus)
      const data = { ...res.data, payStatusName: findData?.name || '' }
      setPageData(data)
    }
  }
  // 返回
  const handBack = () => {
    history.push({
      pathname: '/index/advertising/advertisingOrder',
    })
  }
  // 解析
  const handPayStatus = async () => {
    setLoading(true)
    const { adsOrderId } = state
    const res = await analyzing(adsOrderId)
    if (res.result === 200) {
      message.success('解析成功~')
      getDetails()
    } else {
      setLoading(false)
    }
  }
  // 补发邮件
  const handReissue = async row => {
    console.log(row)
    setLoading(true)
    const { adsOrderId } = state
    const { emailId } = row
    const res = await emaiSend({ adsOrderId, emailId })
    if (res.result === 200) {
      message.success('补发成功~')
      await getMailList()
      setLoading(false)
    } else {
      setLoading(false)
    }
  }
  return (
    <>
      <span className={styles.back} onClick={handBack}>
        <LeftOutlined />
        返回
      </span>
      <span className={styles.reload} onClick={handLoad}>
        <ReloadOutlined />
      </span>
      <div className={`pageContainer ${styles.pageContainer}`}>
        <Spin tip="加载中" spinning={loading}>
          <Form>
            <h3>订单信息</h3>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="线索ID">{pageData.reqid}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="订单ID">{pageData.adsOrderId}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="创建时间">{pageData.ctime}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="业务ID">{pageData.orderId}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="来源">{pageData.source}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="模式">{state.profitType}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="IP">{pageData.ip}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="国家">{pageData.countryCode}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="语言">{pageData.lang}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="答题时间">
                  {`${pageData.startTime || ''} - ${pageData.endTime || ''}`}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="答题耗时">{pageData.expires}秒</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="得分">{pageData.score}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="免费">
                  {pageData.free === 0 ? '否' : '是'}
                  {pageData.free === 0 ? null : ` - ${pageData.freeOrderId}`}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="解析结果">
                  {pageData.analyzing === 1 ? '已解析' : '未解析'}
                  {pageData.payStatus === 15 && pageData.analyzing === 0 ? (
                    <Button
                      size="small"
                      style={{ marginLeft: '10px' }}
                      onClick={handPayStatus}
                    >
                      解析
                    </Button>
                  ) : null}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="解析时间">{pageData.analyzingTime}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="支付状态">{pageData.payStatusName}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="支付金额">{`$ ${
                  pageData.orderMoney || ''
                }`}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="支付时间">{pageData.paySucTime}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="地址">{pageData.orderUrl}</Form.Item>
              </Col>
            </Row>
            <h3>用户信息</h3>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="邮箱">{pageData.userAccount}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="设备信息">{pageData.deviceType}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="设备系统">
                  {`${pageData.systemGroup || ''} ${
                    pageData.browserGroup || ''
                  }`}
                </Form.Item>
              </Col>
            </Row>
            <h3>用户邮件</h3>
            <Row gutter={24}>
              <Col span={24}>
                <Table
                  dataSource={mailList || []}
                  rowKey={item => item.adsPlanId} //React规范中的array都必须有key
                  columns={emailColumns(handReissue)}
                  size="small"
                  bordered
                  pagination={false}
                />
              </Col>
            </Row>
            <h3>用户权益</h3>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="在线结果">
                  {benefit.online === 0 ? '否' : '是'}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="错题集">
                  {benefit.analysis === 0 ? '否' : '是'}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="证书">
                  {benefit.certificate === 0 ? '否' : '是'}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={benefit?.benefitFrees || []}
                  rowKey={item => item.adsPlanId} //React规范中的array都必须有key
                  columns={benefitColumns}
                  size="small"
                  bordered
                  // onChange={changePage}
                  pagination={false}
                />
              </Col>
            </Row>
            <h3>投放信息</h3>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="投放名称/ID">{`${pageData.title || ''} / ${
                  pageData.code || ''
                }`}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="投放方式">{pageData.putinVo?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="管理员">{pageData.adminName}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="测评题">{pageData.questionTitle}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="测评ID">{pageData.questionInfoId}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="分类">{pageData.clasz?.name}</Form.Item>
              </Col>
            </Row>
            <h3>渠道信息</h3>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="渠道类型">{pageData.channelType}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="渠道账户">{pageData.accountName}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="渠道ID">{pageData.accountId}</Form.Item>
              </Col>
            </Row>
            <h3>支付信息</h3>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="支付方案">{pageData.planName}</Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="方案金额">
                  {`$ ${pageData.planPrice}`}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="收款商户">
                  {`${pageData.paymentMchType || ''} - ${
                    pageData.paymentType || ''
                  } `}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="商户名称">{pageData.mchName}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="商户ID">{pageData.mchId}</Form.Item>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={payList}
                  rowKey={item => item.index} //React规范中的array都必须有key
                  columns={payColumns}
                  size="small"
                  bordered
                  // onChange={changePage}
                  pagination={false}
                />
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </>
  )
}
export default withRouter(AdvertiOrderDetails)
