import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取投放管理列表
export const _getAdvertisingList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.advertising.adsList,
    params: params,
  })
}
// 删除投放管理
export const _removeAdvertising = async params => {
  const path = btnApiList.manageDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.advertising.adsInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改投放管理
export const _editeAdvertising = async params => {
  const path = btnApiList.manageEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.advertising.adsInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增投放管理
export const _addAdvertising = async params => {
  const path = btnApiList.manageAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertising.adsInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取投放管理详情
export const _advertisingDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertising.adsInfo}/${params}`,
  })
}
// 修改邮箱
export const _adsEmail = async params => {
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.advertising.adsEmail,
    data: params,
  })
}
// 获取投放语言
export const _getAdsLang = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertising.adsLang}/${params}`,
  })
}

// 修改投放语言
export const _setAdsLang = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertising.setAdsLang,
    data: params,
  })
}
// 投放语言删除
export const _deleteLang = async (id, lang) => {
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.advertising.setAdsLang}/${id}/${lang}`,
  })
}
// 投放商户绑定
export const _adsPayment = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertising.adsPayment,
    data: params,
  })
}
// 获取投放商户列表
export const _getPayment = async (id, payId) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertising.adsPaymentList}/${id}/${payId}`,
  })
}
//删除商户绑定
export const _deletePayment = async (id, payId) => {
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.advertising.adsPayment}/${id}/${payId}`,
  })
}
// 修改投放语言序号
export const _adsLangSeq = async (id1, id2, id3) => {
  return await request({
    method: 'PUT',
    url: `${Api.ADVERSRIING.advertising.adsLangSeq}/${id1}/${id2}/${id3}`,
  })
}
//_adsExport
// 导出投放列表
export const _adsExport = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.advertising.adsExport,
    responseType: 'blob',
    params: params,
  })
}
// 投放管理复制
export const _adsCopy = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertising.adsCopy,
    data: params,
  })
}
