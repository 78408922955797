import { Button, Image, Switch } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = (eventData, pageData) => {
  const { handEdite, handRemove, handSwitch, handContent, handEquity } =
    eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '国家',
      dataIndex: 'countryName',
      key: 'countryName',
      ellipsis: true,
      render: (text, record, index) => {
        const prevRecord = pageData[index - 1]
        if (index > 0 && record.countryName === prevRecord.countryName) {
          return {
            children: '',
            props: {
              rowSpan: 0,
            },
          }
        } else {
          let count = 1
          for (let i = index + 1; i < pageData.length; i++) {
            if (pageData[i].countryName === record.countryName) {
              count++
            } else {
              break
            }
          }
          return {
            children: text,
            props: {
              rowSpan: count,
            },
          }
        }
      },
    },
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      render: icon => (icon ? <Image src={icon} width={24} /> : ''),
    },
    {
      title: '价格',
      key: 'price',
      dataIndex: 'price',
    },
    {
      title: '选中',
      key: 'sel',
      render: row => {
        let status = row.sel ? true : false
        return <Switch checked={status} onChange={() => handSwitch(row)} />
      },
    },
    {
      title: '排序',
      key: 'seq',
      dataIndex: 'seq',
      width: 70,
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'change',
      fixed: 'right',
      width: 260,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.programmeEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <ButtonGroup
              onClick={() => handContent(row)}
              btnId={btnApiList.programmeContent}
              type="link"
            >
              内容
            </ButtonGroup>
            <ButtonGroup
              onClick={() => handEquity(row)}
              btnId={btnApiList.programmeEquity}
              type="link"
            >
              权益
            </ButtonGroup>
            <TipButton
              title="你确定要该方案吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.programmeDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
export { columns }
