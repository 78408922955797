import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取投放方案列表
export const _getPlanList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.advertisingPlan.planList,
    params: params,
  })
}
// 删除投放方案
export const _removePlan = async params => {
  const path = btnApiList.programmeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.advertisingPlan.planInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改投放方案
export const _editePlan = async params => {
  const path = btnApiList.programmeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.advertisingPlan.planInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增投放方案
export const _addPlan = async params => {
  console.log(Api.ADVERSRIING.advertisingPlan.planInfo)
  const path = btnApiList.programmeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertisingPlan.planInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取投放方案详情
export const _planDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertisingPlan.planInfo}/${params}`,
  })
}
// 投放方案选中
export const _planInfoSel = async params => {
  return await request({
    method: 'PUT',
    url: `${Api.ADVERSRIING.advertisingPlan.planInfoSel}/${params}`,
  })
}
// 方案内容
export const _planContent = async (id, lang) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertisingPlan.planContent}/${id}/${lang}`,
  })
}
// 修改方案内容
export const _editePlanContent = async data => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertisingPlan.planContent,
    data: data,
  })
}
// 删除权益方案
export const _deletePlanBenefit = async (id, data) => {
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.advertisingPlan.planBenefit}/${id}/${data}`,
  })
}
//权益方案列表
export const _lanBenefit = async data => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertisingPlan.benefitList}/${data}`,
  })
}
// 修改权益方案
export const _setPlanBenefit = async data => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertisingPlan.planBenefit,
    data: data,
  })
}
// 修改权益方案序号
export const _benefitSeq = async (id1, id2, seq) => {
  return await request({
    method: 'PUT',
    url: `${Api.ADVERSRIING.advertisingPlan.benefitSeq}/${id1}/${id2}/${seq}`,
  })
}
