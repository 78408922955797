const host = process.env.REACT_APP_API_URL

const systemName = '' // 开发-无网关

export const BACKEND_API_URL = host + systemName

const Api = {
  // fildUrl: host + '/core/config/info/AWS_CORE_S3KEY', // 获取上传到aws的参数
  fildUrl: host + '/core/config/info/ALIYUN_OSS_HK', // 获取上传到阿里云的参数
  equityFreeList: host + '/ads/free/list',
  getBiurl: host + '/info', // 获取biurl
  //登录
  LOGIN: {
    login: host + '/login', //登录
    userInfo: host + '/info', //个人信息
    logout: host + '/logout', //退出登录
    btns: host + '/btns', //获取按钮权限
    menus: host + '/menus', //获取按钮权限
    updatePwd: host + '/update/pwd', // 修改密码
  },
  ADVERSRIING: {
    backAbsList: host + '/ads/back/list', // 归因管理列表
    adsOrderList: host + '/ads/order/list', // 投放订单
    adsOrderDetails: host + '/ads/order/info', // 投放订单详情
    adsOrderMail: host + '/ads/order/email/list', // 投放订单邮件列表
    adsOrderBenefit: host + '/ads/order/benefit/info', // 投放订单权益
    adsOrderListLoad: host + '/export/ads/order/list', // 投放订单导出
    payOrderList: host + '/pay/order/list', // 支付订单
    payOrderListLoad: host + '/export/pay/order/list', // 支付订单导出
    clasz: {
      claszList: host + '/clasz/list', // 业务分类列表
      claszInfo: host + '/clasz/info', // 业务分类操作
      claszDef: host + '/clasz/def/info', // 业务分类默认
    },
    advertising: {
      adsList: host + '/ads/list', // 投放管理列表
      adsInfo: host + '/ads/info', // 投放管理操作
      adsEmail: host + '/ads/email', // 修改邮箱
      adsLang: host + '/ads/language/list', // 投放语言
      adsLangSeq: host + '/ads/language/seq', // 修改投放语言
      setAdsLang: host + '/ads/language', // 绑定投放语言
      adsPayment: host + '/ads/payment', // 投放商户绑定
      adsPaymentList: host + '/ads/payment/list', // 投放商户绑定
      adsExport: host + '/export/ads/list', // 导出投放列表
      adsPaymentListStatus: host + '/payment/mch/info/def/status', // 是否默认收款
      adsCopy: host + '/ads/copy', // 投放管理复制
      questionMailList: host + '/question/email/list', // 题库邮箱列表
      questionMailInfo: host + '/question/email/info', // 题库邮箱类型操作
      contentInfo: host + '/question/email/content', // 题库内容详情
      analyzing: host + '/ads/order/analyzing', // 解析
      emaiSend: host + '/ads/order/email/send', // 补发邮件
    },
    advertisingPlan: {
      planList: host + '/ads/plan/list', // 方案列表
      planInfo: host + '/ads/plan/info', // 方案操作
      planInfoSel: host + '/ads/plan/info/sel/', // 方案选中
      planContent: host + '/ads/plan/content', // 方案内容
      planBenefit: host + '/ads/plan/benefit', // 权益方案
      benefitList: host + '/ads/plan/benefit/list', // 权益方案列表
      benefitSeq: host + '/ads/plan/benefit/seq', // 修改权益方案序号
    },
    benefit: {
      benefitTypeList: host + '/benefit/type/list', // 权益类型列表
      benefitTypeInfo: host + '/benefit/type/info', // 权益类型操作
      benefitList: host + '/benefit/list', // 权益管理列表
      benefitInfo: host + '/benefit/info', // 权益管理操作
      benefitContent: host + '/benefit/content/', // 权益内容
    },
    question: {
      analyList: host + '/question/analy/list', // 解析方式列表
      analyInfo: host + '/question/analy/info', // 解析方式操作
      questionList: host + '/question/list', // 题库列表
      questionInfo: host + '/question/info', // 题库操作
      questionContent: host + '/question/content', // 题库内容
      questionConditList: host + '/question/condit/list', // 维度基准列表
      questionConditInfo: host + '/question/condit/info', // 维度操作
      questionConditInfoContent: host + '/question/condit/content', // 维度基准多语言
      questionTitleList: host + '/question/title/list', // 题目列表
      questionTitleInfo: host + '/question/title/info', // 题目操作
      questionTitleContent: host + '/question/title/content', // 题目内容
      questionAnswList: host + '/question/answ/list', // 答案列表
      questionAnswInfo: host + '/question/answ/info', // 答案操作
      questionAnswContent: host + '/question/answ/content', // 答案内容
      questionResultList: host + '/question/result/list', // 结果列表
      questionResultInfo: host + '/question/result/info', // 结果操作
      questionResultContent: host + '/question/result/content', // 结果操作
    },
  },
  OPERATION: {
    domain: {
      domain: host + '/domain/list', // 业务域名列表
      domainInfo: host + '/domain/info', // 业务域名操作
      domainList: host + '/domain/info/list', // 域名列表
      domainListInfo: host + '/domain/info/info', // 域名操作
    },
    channel: {
      channelTypeList: host + '/channel/type/list', // 渠道类型
      channelTypeInfo: host + '/channel/type/info', // 渠道操作
      channelMahList: host + '/channel/mch/list', // 渠道商列表
      channelMahInfo: host + '/channel/mch/info', // 渠道商操作
      channelAccList: host + '/channel/account/list', // 渠道账户
      channelAccInfo: host + '/channel/account/info', // 渠道账户操作
      channelBack: host + '/channel/account/back', // 渠道广告配置
    },
    payManage: {
      paymentList: host + '/payment/mch/list', // 商户列表
      paymentInfo: host + '/payment/mch/info', // 商户操作
      paymentTypeList: host + '/payment/mch/type/list', // 商户类型列表
      paymentTypeInfo: host + '/payment/mch/type/info', // 商户类型操作
      payTypeList: host + '/payment/type/list', // 支付类型列表
      payTypeInfo: host + '/payment/type/info', // 支付类型操作
    },
    company: {
      companyList: host + '/company/list', // 公司列表
      companyInfo: host + '/company/info', // 公司操作
      contractList: host + '/company/contract/list', // 合同列表
      contractInfo: host + '/company/contract/info', // 合同操作
      protocolList: host + '/company/protocol/list', // 协议列表
      protocolInfo: host + '/company/protocol/info', // 协议操作
      protocolContent: host + '/company/protocol/content', // 协议内容
    },
    regionManage: {
      provinceList: host + '/district/province/list', // 省列表
      provinceInfo: host + '/district/province/info', // 省操作
      cityList: host + '/district/city/list', // 市列表
      cityInfo: host + '/district/city/info', // 市操作
      areaList: host + '/district/area/list', // 区列表
      areaInfo: host + '/district/area/info', // 区操作
    },
    currency: {
      currencyList: host + '/currency/list', // 货币列表
      currencyInfo: host + '/currency/info', // 货币操作
    },
    lang: {
      langList: host + '/language/list', // 语言列表
      langInfo: host + '/language/info', // 语言操作
    },
    mailbox: {
      mailboxList: host + '/email/list', // 邮箱列表
      mailboxInfo: host + '/email/info', // 邮箱操作
    },
    mailboxType: {
      mailboxTypeList: host + '/email/type/list', // 邮箱类型列表
      mailboxTypeInfo: host + '/email/type/info', // 邮箱类型操作
    },
    regionState: {
      regionStateList: host + '/district/continent/list', // 大洲列表
      regionStateInfo: host + '/district/continent/info', // 大洲操作
    },
    country: {
      countryList: host + '/district/country/list', // 国家列表
      countryInfo: host + '/district/country/info', // 国家操作
      paymentType: host + '/district/country/payment/type', // 添加支付类型
      paymentList: host + '/district/country/payment/type/list', // 已绑定支付类型列表
      paymentTypeSeq: host + '/district/country/payment/type/seq', // 修改序号
      deleteCountryType: host + '/district/country/payment/type', // 删除支付类型
    },
    region: {
      provinceList: host + '/district/province/list', // 省列表
      provinceInfo: host + '/district/province/info', // 省操作
      cityList: host + '/district/city/list', // 市列表
      cityInfo: host + '/district/city/info', // 市操作
      areaList: host + '/district/area/list', // 区列表
      areaInfo: host + '/district/area/info', // 区操作
    },
  },
  SYSTEM: {
    account: {
      index: host + '/power/account/info/list', // 账号列表
      addAccount: host + '/power/account/info', // 新增账号
      accountRole: host + '/power/account/role/ids', // 账号关联角色
      removeAccount: host + '/power/account/info/', // 删除账号
      accountInfo: host + '/power/account/info/', // 获取账号详情
      resetPwd: host + '/power/account/reset/pwd/', // 重置密码
      editeAccount: host + '/power/account/info', // 修改员工
    },
    org: {
      orgList: host + '/power/org/list', // 获取组织列表
      powerInfo: host + '/power/org/info', // 组织操作
    },
    post: {
      postList: host + '/power/post/list', // 获取组织列表
      postInfo: host + '/power/post/info/', // 岗位操作
    },
    menu: {
      getMenuList: host + '/power/menu/list', // 菜单列表
      removeMenu: host + '/power/menu/info/', // 删除菜单
    },
    role: {
      roleList: host + '/power/role/list', // 角色列表
      roleInfo: host + '/power/role/info/', // 角色操作
      setRoleMenu: host + '/power/role/menu/ids', // 角色关联菜单
    },
    config: {
      configList: host + '/config/list', // 配置管理列表
      configInfo: host + '/config/info', // 配置操作
    },
    enun: {
      enunList: host + '/enum/list', // 字典列表
      enunInfo: host + '/enum/info', // 字典操作
      enumCodeList: host + '/core/enum/list', // 获取通用字段
    },
  },
}
export default Api
