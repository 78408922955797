import {
  Button,
  Col,
  Form,
  Image,
  InputNumber,
  Modal,
  Row,
  Table,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  benefitSeq,
  deletePlanBenefit,
  lanBenefit,
} from 'reduxs/advertising/advertisingPlan'
import ListModal from '../EquityManage/ListModal'
import styles from './index.module.less'
const MailManage = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [pageData, setPageData] = useState([])
  const [number, setNumber] = useState(0)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getDetails = async () => {
    setLoading(true)
    const { adsPlanId } = editeData
    const res = await lanBenefit(adsPlanId)
    setLoading(false)
    if (res.result === 200) {
      const list = res.data || []
      list.forEach((item, index) => {
        item.index = index + 1
        item.isUpdate = false
      })
      setPageData(res.data || [])
    }
  }
  // 添加
  const handAdd = () => {
    setStatus(true)
  }
  // 添加成功
  const handOk = () => {
    setStatus(false)
    getDetails()
  }
  // 编辑
  const handUpdate = row => {
    const { index, seq } = row
    setNumber(seq)
    const list = [...pageData]
    list.forEach(item => {
      item.isUpdate = false
      if (item.inde === index) {
        item.isUpdate = true
      }
    })
    list[index - 1].isUpdate = true
    setPageData(list)
  }
  // 取消编辑
  const handCance = row => {
    const { index, seq } = row
    setNumber(seq)
    const list = [...pageData]
    list[index - 1].isUpdate = false
    setPageData(list)
  }
  // 保存当前的序号
  const handSeq = num => {
    setNumber(num)
  }
  const handSave = async row => {
    const { adsPlanId, benefitId } = row
    const res = await benefitSeq(adsPlanId, benefitId, number)
    if (res.result === 200) {
      message.success('保存成功~')
      getDetails()
    }
  }
  // 删除
  const handDelete = async row => {
    const { benefitId, adsPlanId } = row
    const res = await deletePlanBenefit(adsPlanId, benefitId)
    if (res.result === 200) {
      message.success('删除成功~')
      getDetails()
    }
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
    },
    {
      title: '分类',
      dataIndex: 'clasz',
      key: 'clasz',
      render: row => {
        return row?.name
      },
    },
    {
      title: '权益类型',
      dataIndex: 'benefitType',
      key: 'benefitType',
      width: 140,
      render: row => {
        return row?.name
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      render: icon => {
        return icon ? <Image src={icon} width={24}/> : ''
      },
    },
    {
      title: '价值',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '说明',
      dataIndex: 'descr',
      key: 'descr',
      width: 100,
    },
    {
      title: '排序',
      key: 'seq',
      render: row => {
        const { isUpdate, seq } = row
        return isUpdate ? (
          <InputNumber defaultValue={seq} onChange={e => handSeq(e, row)} />
        ) : (
          seq
        )
      },
    },
    {
      title: '操作',
      key: 'change',
      render: row => {
        const { isUpdate } = row
        return isUpdate ? (
          <>
            <Button type="link" onClick={() => handSave(row)}>
              保存
            </Button>
            <Button type="link" onClick={() => handCance(row)}>
              取消
            </Button>
          </>
        ) : (
          <>
            <Button type="link" onClick={() => handUpdate(row)}>
              编辑
            </Button>
            <Button type="link" onClick={() => handDelete(row)}>
              删除
            </Button>
          </>
        )
      },
    },
  ]
  return (
    <Modal
      width={1000}
      title="方案权益"
      open={editeData.equity}
      onCancel={handleCancel}
      onOk={handleCancel}
      confirmLoading={true}
      footer={[]}
    >
      <div className={styles.formData}>
        {status && (
          <ListModal
            status={status}
            editeData={editeData}
            handSuccess={handOk}
            handleCancel={() => setStatus(false)}
            checkList={pageData || []}
          />
        )}

        <Form form={form}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="名称">{editeData.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="价格">{editeData.price}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="国家">{editeData.countryName}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="状态">
                {editeData?.status === 1 ? '启用' : '禁用'}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="权益" required={true}>
                <Button onClick={handAdd}>添加</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          dataSource={pageData}
          rowKey={item => item.index} //React规范中的array都必须有key
          columns={columns}
          size="small"
          loading={loading}
        />
      </div>
    </Modal>
  )
}
export default MailManage
