import { LeftOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  getPlanList,
  planInfoSel,
  removePlan,
} from 'reduxs/advertising/advertisingPlan'
import { getCountryList } from 'reduxs/operation/country'
import AddEditeModal from '../Compontents/AddEditeModal'
import ContentModal from '../Compontents/ContentModal'
import EquityManage from '../Compontents/EquityManage'
import { columns } from './_help'
import styles from './index.module.less'
let options = {}
const { Option } = Select
const SubjectManage = props => {
  const { history } = props
  const { state } = props.location || {}
  const [editeData, setEditeData] = useState({}) // 编辑存放每一条的数据
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([]) // 列表
  const [pages, setPages] = useState({}) // 页码相关
  const [countryList, setCountryList] = useState([]) // 国家列表
  useEffect(() => {
    options = { page: 1, limit: 10 }
    getList()
    getTypeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取下拉类型
  const getTypeData = async () => {
    const res = await getCountryList({ status: 1, paging: false })
    if (res.result === 200) {
      setCountryList(res.data || [])
    }
  }
  // 获取题目列表
  const getList = async () => {
    setLoading(true)
    const res = await getPlanList({ ...options, adsId: state.adsId })
    setLoading(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 搜索
  const onFinish = values => {
    options = { page: 1, limit: 10, ...values }
    getList()
  }
  // 新增编辑成功
  const handSuccess = () => {
    setEditeData({})
    getList()
  }
  // 新增
  const handAdd = () => {
    const data = { _status: true }
    setEditeData(data)
  }
  // 编辑
  const handEdite = row => {
    const data = { ...row, _status: true }
    setEditeData(data)
  }
  // 删除
  const handRemove = async row => {
    const { adsPlanId } = row
    const res = await removePlan(adsPlanId)
    if (res.result === 200) {
      message.success('删除成功~')
      getList()
    }
  }
  // 切换选中
  const handSwitch = async row => {
    setLoading(true)
    const { adsPlanId } = row
    const res = await planInfoSel(adsPlanId)
    if (res.result === 200) {
      message.success('选中修改成功~')
      getList()
    } else {
      setLoading(false)
    }
  }
  // 内容
  const handContent = row => {
    const data = { ...row, content: true }
    setEditeData(data)
  }
  // 权益
  const handEquity = row => {
    const data = { ...row, equity: true }
    setEditeData(data)
  }
  // 事件合集
  const eventData = {
    handEdite,
    handRemove,
    handSwitch,
    handContent,
    handEquity,
  }
  // 返回
  const handBack = () => {
    history.push({
      pathname: '/index/advertising/advertising',
    })
  }
  return (
    <>
      <span className={styles.back} onClick={handBack}>
        <LeftOutlined />
        返回
      </span>
      <div className="pageContainer">
        <Spin tip="加载中" spinning={loading}>
          {editeData._status && (
            <AddEditeModal
              editeData={editeData}
              handleCancel={() => setEditeData({})}
              handSuccess={handSuccess}
              countryList={countryList}
              state={state}
            />
          )}
          {editeData.equity && (
            <EquityManage
              editeData={editeData}
              handleCancel={() => setEditeData({})}
              handSuccess={handSuccess}
            />
          )}

          {editeData.content && (
            <ContentModal
              editeData={editeData}
              handleCancel={() => setEditeData({})}
              handSuccess={handSuccess}
            />
          )}
          <Form onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="名称">{state.title}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="代码">{state.code}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="分类">{state.clasz?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="投放方式">{state.profitType}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="投放渠道">{state.ct?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="投放账户">
                  {state.channelAccount?.name}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="searchKey" className="formItem">
              <Input placeholder="请搜索" style={{ width: '200px' }} />
            </Form.Item>
            <Form.Item name="countryId" className="formItem">
              <Select
                placeholder="请选择"
                style={{ width: '200px' }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countryList.map((item, index) => (
                  <Option key={index} value={item.countryId}>
                    {`${item.name} (${item.code})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="status" className="formItem">
              <Select
                placeholder="请选择"
                style={{ width: '200px' }}
                allowClear
              >
                <Option value={1}>启用</Option>
                <Option value={0}>禁用</Option>
              </Select>
            </Form.Item>
            <Form.Item className="formItem">
              <Space>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <ButtonGroup onClick={handAdd} btnId={btnApiList.programmeAdd}>
                  新增
                </ButtonGroup>
              </Space>
            </Form.Item>
          </Form>
          <Table
            dataSource={pageData}
            rowKey={item => item.adsPlanId} //React规范中的array都必须有key
            columns={columns(eventData, pageData)}
            size="small"
            scroll={{
              x: 1500,
            }}
            bordered
            onChange={changePage}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              total: pages.count || 0,
              showTotal: total => `共${total}条`,
              defaultPageSize: 10,
              pageSize: options.limit,
              defaultCurrent: 1,
              current: options.page,
              // pageSizeOptions: [10, 30, 50],
              position: ['bottomLeft'],
            }}
          />
        </Spin>
      </div>
    </>
  )
}
export default withRouter(SubjectManage)
