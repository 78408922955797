import { Button } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = eventData => {
  const { handEdite, handRemove, handContent } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '公司',
      dataIndex: 'company',
      key: 'company',
      ellipsis: true,
      render: row => row?.name,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: '类型',
      dataIndex: 'typeName',
      key: 'typeName',
      ellipsis: true,
    },
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'address',
      fixed: 'right',
      width: 200,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.protocolEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <ButtonGroup
              onClick={() => handContent(row)}
              btnId={btnApiList.protocolContent}
              type="link"
            >
              内容
            </ButtonGroup>
            <TipButton
              title="你确定要该公司吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.protocolDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
const dataSource = [
  {
    key: '1',
    index: 1,
    id: 1,
  },
  {
    key: '2',
    index: 2,
    id: 2,
  },
]
export { columns, dataSource }
